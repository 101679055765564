
import { defineComponent } from 'vue';

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import * as Yup from 'yup';
import ApiService from '@/core/services/ApiService';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import { VueCookieNext } from 'vue-cookie-next';
import Multiselect from '@vueform/multiselect';
import axios from 'axios';
import { apiEndpoint } from '@/mixin/apiEndpoint';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'Ethnic Challenged Trainee',
  components: {
    Datatable,
    Multiselect,
  },

  data() {
    return {
      api_url: '',
      tranches: [] as any,
      TranceData: [],
      start_date: '',
      ethnicsubtotal: 0,
      end_date: '',
      TranceLabel: [] as any,
      TpartnerLabel: [] as any,
      selectedDistricts: [] as any,
      selectedTrance: [],
      updatedArray: [] as any,
      TpartnerData: [],
      tranchewiseData: [] as any,
      tableHeader: [
        {
          name: 'Tranche',
          key: 'tranche',
          sortable: true,
          selected: true,
        },
        {
          name: 'Training Partner',
          key: 'association',
          sortable: true,
          selected: true,
        },
        {
          name: 'Course Name',
          key: 'course',
          sortable: true,
          selected: true,
        },
        {
          name: 'Ethnic Group',
          key: 'ethnic',
          sortable: true,
          selected: true,
        },
        {
          name: 'Physically Challenged',
          key: 'challenged',
          sortable: true,
          selected: true,
        },
      ] as any,

      batch: {
        tranche_id: [] as any,
        start_date: '',
        end_date: '',
        entity_id: [] as any,
      },

      data: [],
      grandTotalEthnic: '',
      grandTotalChallenged: '',
      grandTotalEthnicFemale: '',
      grandTotalChallengedFemale: '',
      tranches_info: [],
      optionsTranche: [] as any,
      optionsTPartner: [] as any,
      entityInfos: [] as any,
      componentKey: 0,
      load: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    this.api_url = this.VUE_APP_API_URL;
    await this.getTranche();
    await this.associationList();
  },
  methods: {
    async printPdf() {
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_id', this.batch.entity_id);

      let data = `${this.VUE_APP_API_URL}/api/report/ethnic-disabled-trainee_print`;
      axios
        .post(data, formData)
        .then(response => {
          //window.open(data, '_blank');
          document.write(response.data);
          window.print();
          location.reload();
          //open the new window and write your HTML to it         
        })
    },
    async downloadPdf() {
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_id', this.batch.entity_id);
      await ApiService.post('report/ethnic-disabled-trainee_pdf', formData)
        .then((response) => {
          Swal.fire({
            title: 'Download Successfull!',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'View/Download PDF',
          }).then((result) => {
            if (result.isConfirmed) {
              // redirect to new tab

              let path = `${this.VUE_APP_API_URL}/${response.data}`;

              window.open(path, '_blank');
            }
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async exportTrainee() {
      let formData = new FormData();

      formData.set('tranche_id', this.batch.tranche_id);
      formData.set('entity_id', this.batch.entity_id);

      axios
        .post(
          `${this.VUE_APP_API_URL}/api/report/ethnic-disabled-trainee_export`,
          formData,
          {
            responseType: 'blob',
          }
        )
        .then((response) => {
          const url = URL.createObjectURL(
            new Blob([response.data], {
              type: 'application/vnd.ms-excel',
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'Etnic And Physically Report.xlsx');
          document.body.appendChild(link);
          link.click();
        })
        .catch((response) => {
          console.log(response);
        });
    },
    async getTranche() {
      this.load = true;
      await ApiService.get('configurations/tranche/list')
        .then((response) => {
          this.tranches = response.data.data;
          this.tranches.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.label,
            };
            this.optionsTranche.push(obj);
          });
          this.load = false;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async associationList() {
      let entity_id = '';
      if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
        entity_id = VueCookieNext.getCookie('_entity_id');
      }
      // console.log(entity_id);
      await ApiService.get('entity/list?entity_id=' + entity_id)
        .then((response) => {
          this.entityInfos = response.data.data;
          this.entityInfos.forEach((item, index) => {
            let obj = {
              value: item.id,
              label: item.entity_short_name,
            };
            this.optionsTPartner.push(obj);
          });
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },

    async getData() {
      this.load = true;
      await ApiService.get(
        'report/ethnic-disabled-trainee?tranche_id=' +
        this.batch.tranche_id +
        '&entity_id=' +
        this.batch.entity_id
      )
        .then((response) => {
          this.load = false;
          this.showCourseNotice = true;
          this.tranchewiseData = response.data.data.coursedata;
          this.grandTotalEthnic = response.data.data.TotalEthnic;
          this.grandTotalChallenged = response.data.data.TotalChallenged;
           this.grandTotalEthnicFemale = response.data.data.TotalEthnic_female;
          this.grandTotalChallengedFemale = response.data.data.TotalChallenged_female;
        })
        .then((response) => {
          this.load = false;

          console.log(response);
        });
    },
  },

  // beforeMount() {
  //   this.selectedTableHeader();
  // },
  computed: {},
  setup() { },
});
